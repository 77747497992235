<template>
    <div class="forgetPwd">
        <div style="border-bottom: 1px solid rgba(229, 229, 229, 1);">
            <div class="centerWrapper">
                <div class="top">
                    <div class="logo" @click="$router.push('/')">
                        <!-- <img src="../assets/imgs/brand.png"> -->
                        <img v-if="websiteLogo" :src="websiteLogo">
                        <div class="loginBox"></div>
                    </div>
                    <div class="right">
                        <span @click="$router.replace('/login')">登录</span>
                        <!-- <span>注册</span> -->
                    </div>
                </div>
            </div>
        </div>
        
        <div class="main">
            <div class="centerWrapper">
                <div class="my_steps" v-show="stepActive!=3">
                    <el-steps :active="stepActive">
                        <el-step title="验证手机号" icon="el-icon-mobile-phone"></el-step>
                        <el-step title="修改密码" icon="el-icon-postcard"></el-step>
                        <el-step title="修改成功" icon="el-icon-check"></el-step>
                    </el-steps>
                </div>

                <el-form v-show="stepActive!=3" ref="form" :rules="rules" label-position="left" :model="form" label-width="6.25rem">
                    <el-form-item label="" v-show="stepActive==1" prop="telephone">
                        <div class="cus">
                            <!-- <el-input v-model="form.telephone"></el-input>
                            <div class="explain">请填写您常用的手机号码</div> -->
                            <el-input placeholder="请输入手机号" v-model="form.telephone" class="input-with-select">
                                <el-select v-model="belongVal" slot="prepend" placeholder="">
                                    <el-option
                                    v-for="item in belongOptions"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-input>
                            <div class="explain"></div>
                        </div>
                    </el-form-item>
                    <el-form-item label="" v-show="stepActive==1">
                        <div class="cus">
                            <sliderValid @slideOver="slideOver"></sliderValid>
                            <!-- <div class="explain">拖动滑块到最右侧</div> -->
                            <div class="explain"></div>
                        </div>
                    </el-form-item>

                    <el-form-item label="" v-show="stepActive==1" prop="smsCode">
                        <div class="cus">
                            <el-input v-model="form.smsCode">
                                <el-button slot="append" :style="slideValidResult?styleObj:{}" @click="sendCode">
                                    {{countDownNum>0?countDownNum+'s后重发':'发送验证码'}}
                                </el-button>
                            </el-input>
                            <div class="explain"></div>
                        </div>
                    </el-form-item>
                    <el-form-item label="设置密码" v-show="stepActive==2" prop="password">
                        <div class="cus">
                            <el-input type="password" v-model="form.password" show-password></el-input>
                            <div class="explain">密码长度必须大于6位</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="确认密码" v-show="stepActive==2" prop="confirmPassword">
                        <div class="cus">
                            <el-input type="password" v-model="form.confirmPassword" show-password></el-input>
                            <div class="explain">密码长度必须大于6位</div>
                        </div>
                    </el-form-item>
                    <el-form-item label="">
                        <div class="cus">
                            <el-button type="info" :style="slideValidResult?registerBtnOgj:{}" @click="clickButton">{{['下一步','确定'][stepActive-1]}}</el-button>
                            <div class="explain"></div>
                        </div>
                    </el-form-item>
                </el-form>

                <div class="modResult" v-show="stepActive==3">
                    <img src="../assets/imgs/effect22.png"/>
                    <div>修改成功</div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div>Copyright @2019-2024{{websiteName}}版权所有，并保留所有权利</div>
            <!-- <div>蜀ICP备2023023644号-2</div> -->
            <div @click="toFiling" style="cursor:pointer;width:fit-content;margin:auto">蜀ICP备2024081010号</div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import sliderValid from "@/components/sliderValid2"
import {sendValidCode,forgetPwd} from "@/axios/api"
export default {
    components:{
        sliderValid
    },
    data(){
        var validateTel=(rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入手机号'));
            }else if(!this.$commonFun.regTelephone(value)){
                callback(new Error('请正确输入手机号'));
            }else{
                callback();
            }
        }
        var validatePwd = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入密码'));
            } else {
                if (this.form.confirmPassword !== '') {
                    this.$refs.form.validateField('confirmPassword');
                }
                callback();
            }
        };
        var validatePwd2=(rule,value,callback)=>{
            if (value === '') {
                callback(new Error('请再次输入密码'));
            } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'));
            } else {
                callback();
            }
        }
        return{
            userAcc:"",
            password:"",
            form:{
                telephone:"",
                smsCode:"",
                password:"",
                confirmPassword:"",
            },
            rules:{
                telephone:[
                    {validator:validateTel,trigger:"blur"}
                ],
                smsCode:[
                    {required: true, message: '请输入验证码',trigger:"blur"}
                ],
                password:[
                    {validator:validatePwd,trigger:"blur"}
                ],
                confirmPassword:[
                    {validator:validatePwd2,trigger:"blur"}
                ],
            },
            // form: {
            //     sliderValue: 5,
            // },
            // rules: {
            //     sliderValue: [
            //         { required: true, message: '请滑动滑块进行验证', trigger: 'blur' },
            //         { type: 'number', message: '滑块值必须是数字值', trigger: 'blur' },
            //         { min: 1, max: 10, message: '滑块值必须在 1 到 10 之间', trigger: 'blur' }
            //     ]
            // },
            slideValidResult:false,//滑动验证
            styleObj:{
                background:"#00b894",
                color:"#fff",
                height:"51px"
            },
            registerBtnOgj:{
                background:"#00b894",
                color:"#fff",
                borderColor:"#fff"
            },
            stepActive:1,//步骤
            belongOptions:[
                {
                    label:"+86",
                    value:'+86'
                }
            ],
            belongVal:'+86',
            countDownNum:0,
            websiteLogo:"",
            websiteName:""
        }
    },
    methods: {
        // login(){
        //     axios({
        //         url:_root+"/v1/user/login",
        //         method:"post",
        //         data:{
        //             userName:"18888888888",
        //             password:"4QrcOUm6Wau+VuBX8g+IPg=="
        //         },
        //         headers:{
        //             "Content-Type":"application/x-www-form-urlencoded"
        //         }
        //     }).then(res=>{
        //         console.log("登录",res)
        //     })
        // }

        clickLogin(){
            if(!this.userAcc){
                this.$message("请输入账号");
                return
            }
            if(!this.password){
                this.$message("请输入密码");
                return
            }
            login({
                userName:this.userAcc,
                password:this.password,
            }).then(res=>{
                console.log("登录结果",res);
                if(res.success==true){
                    localStorage.HPUserInfo=JSON.stringify(res.data);
                    this.$router.push("/housingSource")
                }else{
                    this.$message(res.errorMessage)
                }
            })
        },
        // submitForm() {
        //     this.$refs.sliderForm.validateField('sliderValue', (valid) => {
        //         if (!valid) {
        //             alert('验证失败!');
        //         } else {
        //             alert('验证成功!');
        //             // 执行后续操作
        //         }
        //     });
        // },
        slideOver(val){
            console.log("到头")
            this.slideValidResult=true
        },
        // 发送验证码
        sendCode(){
            if(!this.slideValidResult){
                return
            }
            if(this.countDownNum>0){
                this.$message(this.countDownNum+"s后可重发")
                return
            }
            var t=new Date().getTime()
            sendValidCode({
                telephone:this.form.telephone,
                // sign:MD5.createMD5String(this.form.telephone+'YDJJ#@!'+t),
            }).then(res=>{
                console.log("发送验证码",res)
                // if(res.code=200){
                    this.setCountDown()
                // }
            })
        },
        // 点击按钮
        clickButton(){
            if(this.stepActive==1){
                // if(this.form.telephone==''){
                //     this.$message("请输入手机号");
                //     return
                // }
                // if(!this.slideValidResult){
                //     return
                // }
                // if(!this.form.smsCode){
                //     this.$message("请输入验证码");
                //     return
                // }
                this.$refs.form.validateField('telephone',(valid)=>{
                    // console.log(valid)
                    if(valid==""){
                        this.$refs.form.validateField('smsCode',(valid2)=>{
                            if(valid2==""){
                                this.stepActive=2;
                            }
                        })
                    }
                })
                // this.stepActive=2;
                // this.slideValidResult=false;
            }else if(this.stepActive==2){
                // if(!this.form.password){
                //     this.$message("请输入密码");
                //     return
                // }else if(this.form.confirmPassword==''){
                //     this.$message("请确认密码");
                //     return
                // }else if(this.form.password!=this.form.confirmPassword){
                //     console.log(this.form)
                //     this.$message("两次输入密码不一致");
                //     return
                // }

                this.$refs.form.validateField('password',(valid)=>{
                    // console.log(valid)
                    if(valid==""){
                        this.$refs.form.validateField('confirmPassword',(valid2)=>{
                            if(valid2==""){
                                forgetPwd({
                                    telephone:this.form.telephone,
                                    password:this.form.password,
                                    smsCode:this.form.smsCode
                                }).then(res=>{
                                    console.log("修改结果",res);
                                    if(res.code==200){
                                        // this.$message.success(res.msg||res.message)
                                        // setTimeout(()=>{
                                        //     this.$router.replace('/login')
                                        // },2000)
                                        this.stepActive=3
                                    }else{
                                        // this.$message(res.msg||res.message)
                                        this.$commonFun.wrongTip(this,res.msg)
                                    }
                                })
                            }
                        })
                    }
                })

                
            }
            
        },
        setCountDown(){
            this.countDownNum=60
            var timer=setInterval(()=>{
                this.countDownNum--;
                if(this.countDownNum<=0){
                    clearInterval(timer);
                }
            },1000)
        },

        toFiling(){
            location.href="https://beian.miit.gov.cn"
        },
        pxToRem(v){
            return Math.round(v/16*100000)/100000
        },
    },
    created() {
        this.styleObj.height=this.pxToRem(51)+'rem';

        if(sessionStorage.websiteLogo){
            this.websiteLogo=sessionStorage.websiteLogo;
            this.websiteName=sessionStorage.websiteName;
        }
        this.$bus.$on('gettingConfig',()=>{
            this.websiteLogo=sessionStorage.websiteLogo;
            this.websiteName=sessionStorage.websiteName;
        })
    },
}
</script>

<style lang="less" scoped>
.forgetPwd{
    height: 100%;
    // background: url("../assets/imgs/loginBack.webp");
    background-size: 100% 100%;
    background-color: #fff;
    position: relative;
    .top{
        height: 122px;
        // padding: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .logo{
            width: 200px;
            cursor: pointer;
            img{
                width: 100%;
                height: 85px;
                object-fit: contain;
            }
        }
        .right{
            display: flex;
            align-items: center;
            &>span{
                margin-left: 27px;
                font-size: 24px;
                font-size: 20px;
                font-weight: 400;
                color: #333;
                cursor: pointer;
            }
        }
    }
    .main{
        // .slider-container {
        //     display: flex;
        //     align-items: center;
        //     margin-bottom: 20px;
        // }
        margin-top: 100px;
        .my_steps{
            width: 700px;
            margin: auto;
            /deep/.el-steps{
                .el-step__head.is-finish{
                    .el-step__icon{
                        background: rgba(39, 121, 247, 1);
                        color: #fff;
                        border-color: rgba(39, 121, 247, 1);
                    }
                    .el-step__line{
                        border-color: rgba(39, 121, 247, 1);
                        .el-step__line-inner{
                            // border-width: 4px!important;
                            display: none
                        }
                    }
                }
                .el-step__line{
                    height: 0;
                    border-top: 4px dashed rgba(217, 217, 217, 1);
                    background: none;
                    top: 24px;
                }
                .el-step__icon{
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    background: rgba(221, 221, 221, 1);
                }
            }
        }
        .el-form{
            width: 700px;
            margin: auto;
            margin-top: 60px;
            .el-form-item{
                margin-bottom: 69px;
                /deep/.el-form-item__label{
                    height: 51px;
                    line-height: 51px;
                }
                .cus{
                    display: flex;
                    .el-select{
                        width: 80px;
                    }
                    .el-input{
                        width: 400px;
                        /deep/.el-input__inner{
                            height: 51px;
                            line-height: 51px;
                        }
                    }
                    &>.el-button{
                        width: 400px;
                        height: 51px;
                        border-radius: 0;
                    }
                    .explain{
                        width: 200px;
                        margin-left: 30px;
                        font-size: 14px;
                        font-weight: 500;
                        color: rgba(128, 128, 128, 1);
                    }
                }
            }
        }
        .modResult{
            text-align: center;
            padding: 300px 0;
            &>img{
                width: 110px;
                height: 110px;
            }
            &>div{
                margin-top: 20px;
                font-size: 16px;
                font-weight: 400;
                color: rgba(56, 56, 56, 1);
            }
        }
    }
    .footer{
        height: 40px;
        padding: 40px 0;
        text-align: center;
        font-size: 12px;
        font-weight: 700;
        color: rgba(128, 128, 128, 1);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        box-sizing: content-box;
        position: absolute;
        width: 100%;
        bottom: 60px;
        bottom: 0;
    }
    
}
</style>